import { useContext, useEffect, useState } from 'react';

import { IProps } from '../../interfaces/components/toast.interface';
import { FormContext } from '../Form/Form';
import { IFormContext } from '../../interfaces/components/form.interface';
import { formatIconPath } from '../../contexts/Theme';

const Toast = ({
  defaultShow, text, type, image,
}: IProps) => {
  const [showToast, setShowToast] = useState(defaultShow);
  const context = useContext<IFormContext | undefined>(FormContext);

  useEffect(() => {
    setShowToast(
      defaultShow
      || (type === 'showOnSubmit' && (context?.formValidated || context?.formState?.oemCaptchaToken)),
    );
  }, [context?.formValidated, context?.formState?.oemCaptchaToken]);

  return (
    <div
      id="toast-message"
      className={`toast ${showToast ? 'show-toast' : ''}`}
    >
      {image
        && (
          <img
            src={image.noFormattedPath ? image.url : formatIconPath(image.url, true)}
            alt={image.alt}
          />
        )}
      <div className="toast-text">{text}</div>
    </div>
  );
};

Toast.defaultProps = {
  showToast: true,
  showClose: false,
  text: 'message',
};

export default Toast;
