/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';

import api from '../../services/api';
import errorHandler from '../../services/sentry';

import { stripTrailingSlashes } from '../../utils';

import Page from '../Page';
import {
  AccountMenu,
  AccountSelector,
  Button,
  CopyText,
  CustomBulletListItem,
  Form,
  Header,
  Image,
  InlineStyledText,
  Link,
  List,
  ListItem,
  ListSection,
  NativeAuth,
  OemCaptcha,
  Paragraph,
  PinForm,
  ReCAPTCHA,
  TextInput,
  Toast,
  ToggleInput,
  VehicleToggle,
  Footer,
  LoginAnimation,
  LoginLoadingComponent,
  Box,
  ButtonOption,
} from '../../components';

import { useFlashErrorText } from '../../contexts/FlashErrorText';
import { useAppLoading } from '../../contexts/AppLoading';

import { IPageProps } from '../../interfaces/pages/page.interface';

const componentMap = {
  accountMenu: AccountMenu,
  accountSelector: AccountSelector,
  button: Button,
  box: Box,
  customBulletListItem: CustomBulletListItem,
  form: Form,
  copyText: CopyText,
  header: Header,
  image: Image,
  inlineStyledText: InlineStyledText,
  link: Link,
  list: List,
  listItem: ListItem,
  listSection: ListSection,
  loginAnimation: LoginAnimation,
  loginLoadingComponent: LoginLoadingComponent,
  nativeAuth: NativeAuth,
  oemCaptcha: OemCaptcha,
  paragraph: Paragraph,
  pinForm: PinForm,
  reCAPTCHA: ReCAPTCHA,
  textInput: TextInput,
  toggleInput: ToggleInput,
  vehicleToggle: VehicleToggle,
  toast: Toast,
  footer: Footer,
  buttonOption: ButtonOption,
};

const navigationMap = {
  '/login': {
    componentRoute: '/oauth/login',
  },
  '/reauthenticate/login': {
    componentRoute: '/reauth/login',
  },
  '/return/login': {
    componentRoute: '/oauth/return/login',
  },
  '/battery-capacity/login': {
    componentRoute: '/battery-capacity/login',
  },
  '/preview/login': {
    componentRoute: '/preview/login',
  },
};

const Login = (props: IPageProps) => {
  const {
    componentTree, pageMetadata, setComponentTree, isPreview,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { resetError } = useFlashErrorText();
  const { setAppLoading } = useAppLoading();

  const fetchData = useCallback(async (loc) => {
    // first, clear any flash errors
    resetError();
    // set loading indicator
    setAppLoading(true);

    const cleanedPath = stripTrailingSlashes(loc.pathname) as
      | '/login'
      | '/reauthenticate/login'
      | '/return/login'
      | 'battery-capacity/login'
      | '/preview/login';

    const navigation = navigationMap[cleanedPath];
    const navigationRoute = isPreview
      ? `${navigation.componentRoute}${location.search}`
      : navigation.componentRoute;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data } = await api.getComponents(navigationRoute);

    // reset loading indicator
    setAppLoading(false);

    if (data.routes) {
      navigate(data.routes.frontend);
    } else {
      setComponentTree(data);
    }
  }, []);

  useEffect(() => {
    // need to pass the location object here to ensure getComponents
    // uses the updated location pathname
    fetchData(location)
      .catch((e: AxiosError) => {
      // reset loading indicator
        setAppLoading(false);
        // display error info if available
        if (e.response?.data) {
          setComponentTree(e.response.data);
        } else {
          errorHandler(e);
        }
      });
  }, [fetchData, location.pathname]);

  return (
    <Page
      componentMap={componentMap}
      componentTree={componentTree}
      pageMetadata={pageMetadata}
      setComponentTree={setComponentTree}
    />
  );
};

export default Login;
