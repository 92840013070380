/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { useContext, useEffect, useState } from 'react';
import {
  ModeBanner, Renderer, Button, StepperBar,
} from '../../components';
import { useAppLoading } from '../../contexts/AppLoading';
import { ThemeContext } from '../../contexts/Theme';
import Back from '../../assets/images/icons/back.svg?component';

import { IInnerPageProps } from '../../interfaces/pages/page.interface';

const Page = (props: IInnerPageProps) => {
  const {
    componentMap, componentTree, pageMetadata, setComponentTree,
  } = props;
  const { loginPageLoading } = useAppLoading();
  const { updateTheme } = useContext(ThemeContext);
  // to prevent a flash of the wrong theme, we will wait until the theme is set before rendering
  const [isThemeLoading, setIsThemeLoading] = useState(true);

  useEffect(() => {
    if (pageMetadata.theme) {
      setIsThemeLoading(false);
    }
    if (pageMetadata.theme === 'system') {
      const themeQuery = window.matchMedia('(prefers-color-scheme: dark)');

      const handleThemeChange = (e: MediaQueryListEvent) => {
        updateTheme(e.matches ? 'dark' : 'light');
      };

      // set the initial theme
      updateTheme(themeQuery.matches ? 'dark' : 'light');

      // listen for changes to system theme setting
      themeQuery.addEventListener('change', handleThemeChange);

      // cleanup the listener when component unmounts
      return () => {
        themeQuery.removeEventListener('change', handleThemeChange);
      };
    }
    updateTheme(pageMetadata.theme === 'dark' ? 'dark' : 'light');
    return () => {};
  }, [pageMetadata]);

  const getBackButton = () => {
    if (pageMetadata.disableBackFlow
      || (!['brand-selector', 'learn-more', 'login', 'mfa', 'pin', 'return-user-login', 'vin-import', 'connected-service-app-selection'].includes(pageMetadata.id) && !pageMetadata.id.includes('logged-in'))
    ) {
      return null;
    }
    // Calculate how many steps to go back based on flow
    let steps = 1;
    if (pageMetadata.bypassedBrandSelector) {
      steps += 1;
      if (['mfa', 'pin'].includes(pageMetadata.id)) {
        steps += 1;
      }
    }

    // Logic removes the `Back` button from the loading page
    if (loginPageLoading) return null;

    return (
      <Button
        // eslint-disable-next-line no-restricted-globals
        handleClick={() => history.go(-1 * steps)}
        additionalClassNames={`back-button ${pageMetadata.stepperBar ? 'offset-stepper' : ''}`}
        elementAttributes={{
          id: 'back-button',
          type: 'button',
        }}
      >
        <Back className="back-button" />
      </Button>
    );
  };

  if (isThemeLoading) return null;

  return (
    <div className="page-container">
      {pageMetadata.bannerText && <ModeBanner text={pageMetadata.bannerText} />}
      <div className="page" id={pageMetadata.id}>
        <div className="page-content">
          {pageMetadata.stepperBar && (
            <StepperBar
              currentStepIndex={pageMetadata.stepperBar.currentStepIndex}
              totalSteps={pageMetadata.stepperBar.totalSteps}
            />
          )}
          {getBackButton()}
          <Renderer
            componentMap={componentMap}
            componentTree={componentTree}
            setComponentTree={setComponentTree}
          />
        </div>
      </div>
    </div>
  );
};

export default Page;
