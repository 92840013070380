/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import clsx from 'clsx';

import { useContext, useEffect } from 'react';
import { FormContext } from '../Form/Form';

import { IProps } from '../../interfaces/components/buttonOption.interface';
import { IFormContext } from '../../interfaces/components/form.interface';

const ButtonOption = ({
  title,
  subtitle,
  icon,
  value,
  id,
  className,
  imageClassName,
}: IProps) => {
  const context = useContext<IFormContext | undefined>(FormContext);

  const getHandleClick = (buttonOptionValue: string) => () => {
    if (context) {
      context.setFormState({
        selectedButtonOption: buttonOptionValue,
      });
    }
  };

  useEffect(() => {
    if (
      context
      && context?.formState
      && context?.formState?.selectedButtonOption
      && context?.formState?.selectedButtonOption === value
      && context?.submitFormData
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      context?.submitFormData();
    }
  }, [context?.formState.selectedButtonOption]);

  // eslint-disable-next-line max-len
  const selectedButtonOption = (context?.formState as { selectedButtonOption?: string })?.selectedButtonOption;

  return (
    <button
      type="button"
      className={clsx(
        selectedButtonOption === value && 'selected',
        `button-option ${className}`,
      )}
      id={id || value}
      onClick={getHandleClick(value)}
    >
      {icon && <img src={icon} alt="" className={clsx(imageClassName)} />}
      <div>
        <div className="button-option-title">{title}</div>
        {subtitle && <div>{subtitle}</div>}
      </div>
    </button>
  );
};

export default ButtonOption;
