import { useContext, useEffect } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import { FormContext } from '../Form/Form';

import { IFormContext } from '../../interfaces/components/form.interface';

const OemCaptcha = () => {
  const context = useContext<IFormContext | undefined>(FormContext);

  useEffect(() => {
    if (context?.formState?.oemCaptchaToken && context?.submitFormData) {
      context?.submitFormData();
    }
  }, [context?.formState?.oemCaptchaToken]);

  const handleVerify = (token: string) => {
    if (context) {
      context.setFormState({
        ...context.formState,
        oemCaptchaToken: token,
      });
    }
  };

  // check oem captcha type here so we can add additional captcha products to this component
  // in the future
  if (context?.formState.oemCaptchaType === 'hCaptcha' && context?.formState.oemCaptchaSiteKey) {
    return (
      <div className="center">
        <HCaptcha
          sitekey={context?.formState.oemCaptchaSiteKey}
          onVerify={handleVerify}
          sentry={false}
          cleanup={false}
        />
      </div>
    );
  }
  return null;
};

export default OemCaptcha;
